<template>
    <transition name="slide-bottom" mode="out-in">
        <v-spinner></v-spinner>
    </transition>
    <router-view></router-view>
    <CurvedBottomNavigation
        v-if="mobileScreen && auth"
        foreground-color="#303f9f"
        badge-color="#B52C2C"
        background-color="#FFFFFF"
        icon-color="#8C8C8C"
        :options="options"
        v-model="selected"
    />
</template>

<script>
import { CurvedBottomNavigation } from "bottom-navigation-vue";
import "bottom-navigation-vue/dist/style.css";
import VSpinner from "@/components/v-spinner";

export default {
    name: "App",
    components: {
        CurvedBottomNavigation,
        VSpinner,
    },
    data: () => ({
        selected: 1,
        mobileScreen: false,
        options: [
            {
                id: 1,
                icon: "ri-home-3-line",
                title: "Home",
                path: { name: "Dashboard" },
            },
            {
                id: 2,
                icon: "ri-user-location-fill",
                title: "Presensi",
                path: { name: "Presensi" },
            },
            {
                id: 3,
                icon: "ri-walk-fill",
                title: "Izin",
                path: { name: "Izinku" },
            },
            {
                id: 4,
                icon: "ri-calendar-event-fill",
                title: "Tukar Shift",
                path: { name: "TukarShift" },
            },
            {
                id: 5,
                icon: "ri-calendar-check-fill",
                title: "Jadwal",
                childs: [
                    {
                        id: 303,
                        icon: "ri-calendar-check-fill",
                        title: "Jadwalku",
                        path: { name: "Jadwalku" },
                    },
                    {
                        id: 302,
                        icon: "ri-calendar-2-line",
                        title: "Kerja",
                        path: { name: "JadwalList" },
                    },
                ],
            },
            {
                id: 6,
                icon: "ri-history-fill",
                title: "History",
                path: { name: "HistoryPresensi" },
            },
            // {
            //     id: 5,
            //     icon: "ri-shield-user-fill",
            //     title: "User",
            //     path: { name: "HistoryPresensi" },
            // },
        ],
        listenersStarted: false,
        idToken: "",
    }),
    computed: {
        auth() {
            console.log(this.$store.state?.auth?.data?.role);
            return this.$store.state?.auth?.data?.role !== undefined;
        },
    },
    created() {
        var windowSize = document.documentElement.clientWidth;
        if (windowSize < 767) {
            this.mobileScreen = true;
        } else {
            this.mobileScreen = false;
        }
    },
};
</script>
