import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/account/login.vue"),
    meta: {
      authRequired: false,
      title: "Login",
      async beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in

        /* GET ACTION VUEX STORE */
        let authStore = await store.dispatch("auth/getLogin");
        if (authStore.data !== undefined) {
          // Redirect to the home page instead
          next({ name: "Dashboard" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      authRequired: false,
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      authRequired: false,
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "Logout",
    meta: {
      title: "Logout",
      authRequired: true,
      role: ["SUPER_ADMIN","STAF","KEPALA","KASUB","KABID", "DIREKTUR"],
    },
  },
  {
    path: "/",
    name: "Dashboard",
    meta: {
      title: "Dashboard",
      authRequired: true,
      role: ["SUPER_ADMIN","STAF","KEPALA","KASUB","KABID", "DIREKTUR"],
    },
    component: () => import("@/views/dashboard/Index"),
  },
  {
    path: "/m-karyawan",
    name: "Karyawan",
    meta: {
      title: "Master Karyawan",
      authRequired: true,
      role: ["SUPER_ADMIN","DIREKTUR"],
    },
    component: () => import("@/views/m_karyawan/Index"),
  },
  {
    path: "/m-lokasi",
    name: "Lokasi",
    meta: { title: "Lokasi", authRequired: true, role: ["SUPER_ADMIN"] },
    component: () => import("@/views/m_lokasi/Index"),
  },
  {
    path: "/m-shift",
    name: "Shift",
    meta: { title: "Shift", authRequired: true, role: ["SUPER_ADMIN"] },
    component: () => import("@/views/m_shift/Index"),
  },
  {
    path: "/m-izin",
    name: "Izin",
    meta: { title: "Izin", authRequired: true, role: ["SUPER_ADMIN"] },
    component: () => import("@/views/m_izin/Index"),
  },
  {
    path: "/m-unit",
    name: "Unit",
    meta: { title: "Unit", authRequired: true, role: ["SUPER_ADMIN"] },
    component: () => import("@/views/m_unit/Index"),
  },
  {
    path: "/on-progress",
    name: "ProgressPresensi",
    meta: {
      title: "Progress Presensi",
      authRequired: true,
      role: ["SUPER_ADMIN","STAF","KEPALA","KASUB","KABID", "DIREKTUR"],
    },
    component: () => import("@/views/on_progress/Index"),
  },
  {
    path: "/m-jabatan",
    name: "Jabatan",
    meta: { title: "Jabatan", authRequired: true, role: ["SUPER_ADMIN"] },
    component: () => import("@/views/m_jabatan/Index"),
  },
  {
    path: "/presensi",
    name: "Presensi",
    meta: {
      title: "Presensi",
      authRequired: true,
      role: ["SUPER_ADMIN", "STAF","KEPALA","KASUB","KABID", "DIREKTUR"],
    },
    component: () => import("@/views/presensi/Index"),
  },
  {
    path: "/jadwal-ku",
    name: "Jadwalku",
    meta: {
      title: "Jadwalku",
      authRequired: true,
      role: ["SUPER_ADMIN", "STAF","KEPALA","KASUB","KABID", "DIREKTUR"],
    },
    component: () => import("@/views/jadwalku/Index"),
    redirect: "/jadwal-ku/list",
    children: [
      {
        path: "list",
        name: "JadwalKuList",
        meta: {
          title: "JadwalKu",
          authRequired: true,
          role: ["SUPER_ADMIN", "STAF","KEPALA","KASUB","KABID", "DIREKTUR"],
        },
        component: () => import("@/views/jadwalku/List"),
      },
    ],
  },
  {
    path: "/izin-ku",
    name: "Izinku",
    meta: {
      title: "Izinku",
      authRequired: true,
      role: ["SUPER_ADMIN", "STAF","KEPALA","KASUB","KABID", "DIREKTUR"],
    },
    component: () => import("@/views/izinku/Index"),
  },
  {
    path: "/tukar-shift",
    name: "TukarShift",
    meta: {
      title: "TukarShift",
      authRequired: true,
      role: ["SUPER_ADMIN", "STAF","KEPALA","KASUB","KABID", "DIREKTUR"],
    },
    component: () => import("@/views/tukar_shift/Index"),
    children: [
      {
        path: "list",
        name: "TukarShiftList",
        meta: {
          title: "Tukar Shift",
          authRequired: true,
          role: ["SUPER_ADMIN", "STAF","KEPALA","KASUB","KABID", "DIREKTUR"],
        },
        component: () => import("@/views/tukar_shift/List"),
      },
    ],
  },
  {
    path: "/jadwal",
    name: "Jadwal",
    meta: {
      title: "Jadwal",
      authRequired: true,
      role: ["SUPER_ADMIN", "STAF","KEPALA","KASUB","KABID", "DIREKTUR"],
    },
    component: () => import("@/views/jadwal/Index"),
    redirect: "/jadwal/list",
    children: [
      {
        path: "list",
        name: "JadwalList",
        meta: {
          title: "Jadwal",
          authRequired: true,
          role: ["SUPER_ADMIN", "STAF","KEPALA","KASUB","KABID", "DIREKTUR"],
        },
        component: () => import("@/views/jadwal/List"),
      },
      {
        path: "approval",
        name: "ApprovalJadwal",
        meta: {
          title: "Approval Jadwal",
          authRequired: true,
          role: ["SUPER_ADMIN", "KABID", "DIREKTUR"],
        },
        component: () => import("@/views/jadwal/Approval"),
      },
      {
        path: "tambah",
        name: "JadwalTambah",
        meta: {
          title: "Jadwal Tambah",
          authRequired: true,
          role: ["SUPER_ADMIN", "KEPALA","KASUB","KABID", "DIREKTUR"],
        },
        component: () => import("@/views/jadwal/Tambah"),
      },
      {
        path: "edit/:nip?",
        name: "JadwalEdit",
        meta: {
          title: "Jadwal Edit",
          authRequired: true,
          role: ["SUPER_ADMIN", "STAF","KEPALA","KASUB","KABID", "DIREKTUR"],
        },
        component: () => import("@/views/jadwal/Edit"),
      },
    ],
  },
  {
    path: "/history",
    name: "History",
    meta: {
      title: "history",
      authRequired: true,
      role: ["SUPER_ADMIN", "STAF","KEPALA","KASUB","KABID", "DIREKTUR"],
    },
    component: () => import("@/views/history/Index"),
    children: [
      {
        path: "presensi",
        name: "HistoryPresensi",
        meta: {
          title: "History Presensi",
          authRequired: true,
          role: ["SUPER_ADMIN", "STAF","KEPALA","KASUB","KABID", "DIREKTUR"],
        },
        component: () => import("@/views/history/presensi/Index"),
      },
      {
        path: "tukar-shift",
        name: "HistoryTukarShift",
        meta: {
          title: "History Tukar Shift",
          authRequired: true,
          role: ["SUPER_ADMIN", "KEPALA", "KABID", "KASUB","DIREKTUR"],
        },
        component: () => import("@/views/history/tukar_jadwal/Index"),
      },
      {
        path: "jadwal",
        name: "HistoryJadwal",
        meta: {
          title: "History Jadwal",
          authRequired: true,
          role: ["SUPER_ADMIN", "KABID", "KASUB","DIREKTUR"],
        },
        component: () => import("@/views/history/jadwal/Index"),
      },
      {
        path: "izin",
        name: "HistoryIzin",
        meta: {
          title: "History Izin",
          authRequired: true,
          role: ["SUPER_ADMIN", "KABID", "KASUB","DIREKTUR"],
        },
        component: () => import("@/views/history/izin/Index"),
      },
      {
        path: "karyawan",
        name: "HistoryKaryawan",
        meta: {
          title: "History Karyawan",
          authRequired: true,
          role: ["SUPER_ADMIN", "KABID", "KASUB","DIREKTUR"],
        },
        component: () => import("@/views/history/karyawan/Index"),
      },
    ],
  },
  {
    path: "/rekap",
    name: "Rekap",
    meta: { title: "rekap", authRequired: true, role: ["SUPER_ADMIN"] },
    redirect: "/rekap/presensi",
    children: [
      {
        path: "presensi",
        name: "RekapPresensi",
        meta: {
          title: "Rekap Presensi",
          authRequired: true,
          role: ["SUPER_ADMIN","DIREKTUR"],
        },
        component: () => import("@/views/rekap/presensi/Index"),
      },
    ],
  },
  {
    path: "/profil",
    name: "Profile",
    meta: {
      title: "Profil",
      authRequired: true,
      role: ["SUPER_ADMIN","STAF", "KEPALA", "KABID", "KASUB","DIREKTUR"],
    },
    component: () => import("@/views/profil/Index"),
  },
];
