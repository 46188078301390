// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCadnEdhrZxzu2VkzTzVSla4YDNFj9W_O8",
  authDomain: "gs-absen.firebaseapp.com",
  projectId: "gs-absen",
  storageBucket: "gs-absen.appspot.com",
  messagingSenderId: "353624386534",
  appId: "1:353624386534:web:c6c5873176e43caf644427",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

const key =
  "BCpUMmKEOvRyWk6lorcTGoRr9aMp1ep5Gpi8Cf6Bbc6QuXW34U8vb6ejH5FOUtXLl2zJDY8sNCKdGr7f2S4nxa0";
navigator.serviceWorker.register("firebase-msg-sw.js").then((registration) => {
  getToken(messaging, {
    serviceWorkerRegistration: registration,
    vapidKey: key,
  })
    .then(async (currentToken) => {
      if (currentToken) {
        currentToken;
        console.log("client token", currentToken);
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
});
